import React from 'react';
import { arrayOf, object } from 'prop-types';
import { ThbDouble } from '@ads-placements-frontend/thb-double';
import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';

function TopHomeBannerDouble({ items }) {
  return (
    <Section type="adn" className="AD_ADN_area">
      <ThbDouble items={items} preload />
    </Section>
  );
}

TopHomeBannerDouble.propTypes = {
  items: arrayOf(object),
};

export default withTracker(TopHomeBannerDouble);
